<template>
  <div :class="$style.order_cart_list">
    <div v-for="(item, _index) in cartList" :key="item.cartDetail.sku.document_id" :class="$style.item">
      <OrderSideCartListItem
        v-if="
          (!item.cartDetail.embroideries || item.cartDetail.embroideries.length <= 0) &&
          (!item.cartDetail.gifts || item.cartDetail.gifts.length <= 0)
        "
        :cart-detail="item.cartDetail"
        :can-wrapping="item.wrapping.length > 0"
        :is-confirm="isConfirm"
        :item-border="itemsBorder"
        @on-add-cart-item="
          addCartItem(
            item.cartDetail.product.document_id,
            item.cartDetail.sku.document_id,
            item.cartDetail.product.cart_only_gift,
            item.cartDetail.quantity,
            item.cartDetail.sku.site_id,
            item.cartDetail.sku.is_reservation,
            item.cartDetail.sku.net_stock_quantity,
            item.cartDetail.sku.net_stock_quantity_limit,
            item.cartDetail.product.order_quantity_limit,
            item.cartDetail.product.order_quantity_limit_type,
          )
        "
        @on-reduce-cart-item="reduceCartItem(item.cartDetail.sku.document_id, item.cartDetail.quantity)"
        @on-open-select-gift-modal="openSelectGiftModal(_index)"
      />

      <div
        v-if="item.cartDetail.embroideries && item.cartDetail.embroideries.length >= 1"
        :class="$style.purchase_options_list"
      >
        <OrderSideCartListItem
          v-if="item.cartDetail.count > 0 && !item.cartDetail.gifts?.length"
          :cart-detail="item.cartDetail"
          :is-confirm="isConfirm"
          :item-border="itemsBorder"
          @on-add-cart-item="
            addCartItem(
              item.cartDetail.product.document_id,
              item.cartDetail.sku.document_id,
              item.cartDetail.product.cart_only_gift,
              item.cartDetail.quantity,
              item.cartDetail.sku.site_id,
              item.cartDetail.sku.is_reservation,
              item.cartDetail.sku.net_stock_quantity,
              item.cartDetail.sku.net_stock_quantity_limit,
              item.cartDetail.product.order_quantity_limit,
              item.cartDetail.product.order_quantity_limit_type,
            )
          "
          @on-reduce-cart-item="reduceCartItem(item.cartDetail.sku.document_id, item.cartDetail.quantity)"
        />

        <OrderSideCartListItem
          v-for="embroidery in item.cartDetail.embroideries"
          :key="embroidery.embroidered_sku_id"
          :cart-detail="item.cartDetail"
          :embroidery="embroidery"
          :is-confirm="isConfirm"
          :item-border="itemsBorder"
          @on-add-cart-item="
            addCartItem(
              item.cartDetail.product.document_id,
              item.cartDetail.sku.document_id,
              item.cartDetail.product.cart_only_gift,
              item.cartDetail.quantity,
              item.cartDetail.sku.site_id,
              item.cartDetail.sku.is_reservation,
              item.cartDetail.sku.net_stock_quantity,
              item.cartDetail.sku.net_stock_quantity_limit,
              item.cartDetail.product.order_quantity_limit,
              item.cartDetail.product.order_quantity_limit_type,
              undefined,
              embroidery,
            )
          "
          @on-reduce-cart-item="
            reduceCartItem(item.cartDetail.sku.document_id, item.cartDetail.quantity, undefined, embroidery)
          "
        />
      </div>

      <div v-if="item.cartDetail.gifts && item.cartDetail.gifts.length >= 1" :class="$style.purchase_options_list">
        <OrderSideCartListItem
          v-if="item.cartDetail.count > 0"
          :cart-detail="item.cartDetail"
          :can-wrapping="item.wrapping.length > 0"
          :is-confirm="isConfirm"
          :item-border="itemsBorder"
          @on-add-cart-item="
            addCartItem(
              item.cartDetail.product.document_id,
              item.cartDetail.sku.document_id,
              item.cartDetail.product.cart_only_gift,
              item.cartDetail.quantity,
              item.cartDetail.sku.site_id,
              item.cartDetail.sku.is_reservation,
              item.cartDetail.sku.net_stock_quantity,
              item.cartDetail.sku.net_stock_quantity_limit,
              item.cartDetail.product.order_quantity_limit,
              item.cartDetail.product.order_quantity_limit_type,
            )
          "
          @on-reduce-cart-item="reduceCartItem(item.cartDetail.sku.document_id, item.cartDetail.quantity)"
          @on-open-select-gift-modal="openSelectGiftModal(_index, true)"
        />

        <OrderSideCartListItem
          v-for="gift in item.cartDetail.gifts"
          :key="gift.sku_code"
          :cart-detail="item.cartDetail"
          :can-wrapping="item.wrapping.length > 0"
          :embroidery="gift.embroidery"
          :gift="gift"
          :is-confirm="isConfirm"
          :item-border="itemsBorder"
          has-set-wrapping
          @on-add-cart-item="
            addCartItem(
              item.cartDetail.product.document_id,
              item.cartDetail.sku.document_id,
              item.cartDetail.product.cart_only_gift,
              item.cartDetail.quantity,
              item.cartDetail.sku.site_id,
              item.cartDetail.sku.is_reservation,
              item.cartDetail.sku.net_stock_quantity,
              item.cartDetail.sku.net_stock_quantity_limit,
              item.cartDetail.product.order_quantity_limit,
              item.cartDetail.product.order_quantity_limit_type,
              gift,
              gift.embroidery,
            )
          "
          @on-reduce-cart-item="reduceCartItem(item.cartDetail.sku.document_id, item.cartDetail.quantity, gift)"
          @on-init-select-gift="initSelectGift(_index)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CartGift, CartEmbroidery } from "@tential/ec-gql-schema/models/order"
import { SkuWithGift, SkuWithEmbroidery } from "@tential/ec-gql-schema/types/cart"
import { errorMessage } from "@tential/ec-gql-schema/utils/functions/error"
import { useConfirmDialog } from "~/composables/useConfirmDialog"
import { useCart } from "~/composables/useCart"
import { useToast } from "~/state"
import { CartListWithGift } from "~/types/cart"
import { checkGiftItemLimit } from "~/utils/functions/validate"

const props = withDefaults(
  defineProps<{
    /** カートデータ */
    cartList: CartListWithGift[]
    /** サブスク */
    isSubscription?: boolean
    /** 確認画面かどうか */
    isConfirm?: boolean
    /** 交換クーポンを使用しているか */
    isExchangeCoupon?: boolean
    /** ListItemの境界線を表示するかどうか */
    itemsBorder?: boolean
  }>(),
  {
    isSubscription: false,
    isConfirm: false,
    isExchangeCoupon: false,
    itemsBorder: false,
  },
)

type Emits = {
  (e: "openSelectGift", index: number, isNotGift: boolean): void
  (e: "initSelectGift", index: number): void
}
const emit = defineEmits<Emits>()

const { proceed } = useConfirmDialog()
const toast = useToast()
const cart = useCart()
const gtag = useGtag()
const config = useRuntimeConfig()
const addBtnDisplay = ref<boolean>(true)
/** ギフト商品＆ラッピング済み商品を除いた商品の数量 */
const notGiftSkuQuantity = computed(() => {
  return cart.cartLine.value.reduce((total, cartItem) => {
    if (!cartItem.product.is_gift) return total + cartItem.count
    return total
  }, 0)
})

const reduceCartItem = async (
  skuId: string,
  skuAmt: number,
  gift?: SkuWithGift,
  embroidery?: SkuWithEmbroidery,
): Promise<void> => {
  if (skuAmt === 1) {
    if (!(await proceed("本当に削除してよろしいですか？"))) return
  }

  await cart.removeCartItem(skuId, gift, embroidery)

  // カートに購入点数制限がある商品があったら同時に削除
  if (cart.cartLine.value.length > 0) {
    for (const item of cart.cartLine.value) {
      if (item.product.order_quantity_limit && item.product.order_quantity_limit > 0) {
        if (item.product.order_quantity_limit_type === "order") {
          // 数量が購入点数制限以上の場合
          if (item.quantity > item.product.order_quantity_limit) {
            toast?.showErrorToasted(`${item.product.name}は、購入点数上限を超えたためカートから削除されました`)
            await cart.removeCartItem(item.sku.document_id)
          }
        } else if (item.product.order_quantity_limit_type === "not_gift_sku") {
          // 数量がカート個数*購入点数制限以上の場合
          if (item.quantity > item.product.order_quantity_limit * notGiftSkuQuantity.value) {
            toast?.showErrorToasted(`${item.product.name}は、購入点数上限を超えたためカートから削除されました`)
            await cart.removeCartItem(item.sku.document_id)
          }
        }
      }
    }
  }
}

// カートから追加
const addCartItem = async (
  productId: string,
  skuId: string,
  cart_only_gift: boolean,
  quantity: number,
  site_id?: string,
  is_reservation?: boolean,
  net_stock_quantity?: number,
  net_stock_quantity_limit?: number,
  order_quantity_limit?: number,
  order_quantity_limit_type?: string,
  gift?: SkuWithGift,
  embroidery?: SkuWithGift["embroidery"],
) => {
  if (props.isExchangeCoupon === true) return
  if (props.isSubscription) {
    // store.dispatch("addSubscriptionCartList", {
    //   productId: _productId,
    //   skuId: _skuId,
    //   siteId: _site_id,
    //   subscriptionItemId: _subscriptionItemId,
    //   isReservation: _is_reservation,
    // })
  } else {
    try {
      // 連打防止フラグ
      addBtnDisplay.value = false
      // 購入点数制限がある商品の点数チェック
      if (quantity && order_quantity_limit && order_quantity_limit > 0 && order_quantity_limit_type) {
        if (order_quantity_limit_type === "order") {
          // 数量が購入点数制限以上の場合はエラー
          if (quantity >= order_quantity_limit) {
            throw new Error(`この商品は、1回のご注文につき、${order_quantity_limit}個まで購入できます`)
          }
        } else if (order_quantity_limit_type === "not_gift_sku") {
          // 数量がカート個数*購入点数制限以上の場合はエラー
          if (quantity >= order_quantity_limit * notGiftSkuQuantity.value) {
            throw new Error(
              `この商品は、商品1点（ラッピング袋・カードを除く）につき、${order_quantity_limit}個まで購入できます`,
            )
          }
        }
      }

      // ギフト商品の購入上限を超えている場合はエラーを表示
      if (checkGiftItemLimit(cart.cartLine.value, cart_only_gift, 1)) {
        throw new Error("単品でのギフト資材は、1回のご注文で最大3点までしかカートに追加できません")
      }

      if (is_reservation && quantity && Number(net_stock_quantity) - quantity < Number(net_stock_quantity_limit)) {
        const availableQuantity =
          (net_stock_quantity ?? 0) - (net_stock_quantity_limit ?? 0) + 1 < 0
            ? 0
            : (net_stock_quantity ?? 0) - (net_stock_quantity_limit ?? 0) + 1
        throw new Error(`予約購入できる個数は${availableQuantity}個までです。`)
      }

      await cart.addCartItem({
        product_id: productId,
        sku_id: skuId,
        site_id,
        is_reservation,
        net_stock_quantity,
        net_stock_quantity_limit,
        gift: gift ? formatGift(gift) : undefined,
        embroidery: embroidery ? formatEmbroidery(embroidery) : undefined,
      })
    } catch (e) {
      const error = errorMessage(e)
      toast?.showErrorToasted(error)
    } finally {
      addBtnDisplay.value = true
    }
  }
  gaPush(skuId)
}

const gaPush = (_skuId: string) => {
  if (config.public.APP_ENV === "production") {
    gtag("event", "add_to_cart", {
      currency: "JPY",
      items: [
        {
          item_id: _skuId,
        },
      ],
    })
  }
}

const formatGift = (gift: SkuWithGift): CartGift => {
  return {
    sku_id: gift.sku_id,
    message: gift.message,
    product_name: gift.product_name,
    sku_name: gift.sku_name,
    sku_code: gift.sku_code,
    size: gift.size,
    price: gift.price,
    img: gift.img,
    message_img: gift.message_img,
    message_price: gift.message_price,
    message_sku_code: gift.message_sku_code,
    message_sku_id: gift.message_sku_id,
    card: gift.card,
    card_img: gift.card_img,
    card_price: gift.card_price,
    card_sku_code: gift.card_sku_code,
    card_sku_id: gift.card_sku_id,
    product_id: gift.product_id,
  }
}

const openSelectGiftModal = (index: number, isNotGift: boolean = false): void => {
  emit("openSelectGift", index, isNotGift)
}

const initSelectGift = (index: number): void => {
  emit("initSelectGift", index)
}

const formatEmbroidery = (embroidery: SkuWithGift["embroidery"]): CartEmbroidery => {
  const formatEmbroidery = {
    text: embroidery.text,
    position: embroidery.position,
    color: embroidery.color,
    font: embroidery.font,
    price: embroidery.price,
  }
  return formatEmbroidery
}
</script>

<style scoped module lang="scss">
.order_cart_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.25rem 0;
  .item {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    .purchase_options_list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
    }
  }
}
</style>
