<template>
  <div :class="$style.order_cart_price">
    <dl>
      <dt>税込小計</dt>
      <dd>&yen; {{ subTotal.toLocaleString() }}</dd>
    </dl>
    <dl>
      <dt>税込送料(全国一律)</dt>
      <dd>&yen; {{ postagePrice.toLocaleString() }}</dd>
    </dl>
    <dl v-if="commissionPrice > 0">
      <dt>手数料(税込)</dt>
      <dd>&yen; {{ commissionPrice.toLocaleString() }}</dd>
    </dl>
    <dl v-if="giftPrice > 0">
      <dt>ラッピング料(税込)</dt>
      <dd>&yen; {{ giftPrice.toLocaleString() }}</dd>
    </dl>
    <dl v-if="embroideryPrice > 0">
      <dt>刺繍サービス料(税込)</dt>
      <dd>&yen; {{ embroideryPrice.toLocaleString() }}</dd>
    </dl>
    <dl v-if="discountPrice > 0">
      <dt>割引</dt>
      <dd :class="$style.discount">&yen; -{{ discountPrice.toLocaleString() }}</dd>
    </dl>
    <dl v-if="subscriptionDiscountPrice > 0">
      <dt>定期購入割引</dt>
      <dd :class="$style.discount">&yen; -{{ subscriptionDiscountPrice.toLocaleString() }}</dd>
    </dl>
    <hr />
    <dl>
      <dt>合計</dt>
      <dd :class="$style.total">&yen; {{ total.toLocaleString() }}</dd>
    </dl>
    <p v-if="showRemainAmount" :class="$style.remain_amount">
      あと
      <span> &yen;{{ remainAmount.toLocaleString() }} </span>
      購入で
      <span>{{ discountText }}</span>
      OFF!
    </p>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  subTotal: {
    type: Number,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
  postagePrice: {
    type: Number,
    default: 0,
  },
  discountPrice: {
    type: Number,
    default: 0,
  },
  subscriptionDiscountPrice: {
    type: Number,
    default: 0,
  },
  commissionPrice: {
    type: Number,
    default: 0,
  },
  giftPrice: {
    type: Number,
    default: 0,
  },
  embroideryPrice: {
    type: Number,
    default: 0,
  },
  remainAmount: {
    type: Number,
    default: 0,
  },
  recommendDiscountMethod: {
    type: String,
    default: "",
  },
  recommendDiscountRate: {
    type: Number,
    default: 0,
  },
  recommendDiscountFixedAmount: {
    type: Number,
    default: 0,
  },
  isCouponApplied: {
    type: Boolean,
    default: false,
  },
})

const route = useRoute()
const queryCouponCode = typeof route.query.coupon_code === "string" ? route.query.coupon_code : ""

const showRemainAmount = computed(() => {
  return !props.isCouponApplied && props.remainAmount > 0 && !queryCouponCode
})
const discountText = computed(() => {
  if (props.recommendDiscountMethod === "percentage") {
    return `${props.recommendDiscountRate}%`
  }
  if (props.recommendDiscountMethod === "fixed_amount") {
    return `${props.recommendDiscountFixedAmount.toLocaleString()}円`
  }
})
</script>

<style module scoped lang="scss">
.order_cart_price {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  dl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $font-size-14;
    line-height: 1.5;
    dt {
      font-weight: 600;
    }
    dd {
      font-size: $font-size-16;
      @include main-font;
      &.total {
        font-size: $font-size-24;
      }
      &.discount {
        color: $danger;
      }
    }
  }
  .remain_amount {
    @include main-font;
    text-align: right;
    font-size: $font-size-12;
    line-height: 1.5;
    span {
      @include main-font;
      font-size: $font-size-18;
      color: $danger;
    }
  }
}
</style>
